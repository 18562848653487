import styled from "styled-components"

export const HeaderGroup = styled.div`
  text-align: center;
  margin: 64px 0 40px;
  padding: 32px;

  & h1 {
    color: #0080ED;
  }

  & h2 {
    color: #989898;
    font-weight: 300;
    padding-top: 16px;
  }
`
