import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function International() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Da matrícula</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Do cancelamento e reembolso</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Disposições gerais</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Política de Matrícula, Cancelamento e Reembolso - Imersões Internacionais</h1>
        <h3>Esta política é vigente apenas para compras efetuadas até 14/05/2023.<br />
          Para compras efetuadas a partir de 15/05/2023, <a href={'https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao-v2'}>acesse este link.</a></h3>

        <p>A presente política de matrícula, cancelamento e reembolso tem por objetivo informar sobre as regras estabelecidas para as matrículas, eventual cancelamento e reembolso das Imersões Internacionais que o Participante se inscrever em nosso site, com amparo no artigo 49 da Lei nº 8.078/1990.</p>
        <p>O Participante que se inscrever em uma de nossas Imersões Internacionais, atesta que leu integralmente e está de acordo os termos de uso, a política de privacidade e a política de matrícula, cancelamento e reembolso.</p>

        <p id="1"><strong>1. DA MATRÍCULA:</strong></p>
        <p>1.1 A vaga do Participante na Imersão Internacional somente será reservada e concretizada após a devida comprovação de pagamento pela instituição financeira.</p>
        <p>1.2. A troca de Participantes deverá ser alterada diretamente na plataforma (visualizar compra > participantes > alterar) com até 20 dias de antecedência a realização da Imersão Internacional. Caso não haja tal comunicação/alteração o substituto não poderá fazer parte da Imersão Internacional.</p>

        <p id="2"><strong>2. DO CANCELAMENTO E REEMBOLSO:</strong></p>
        <p>2.1. Em caso de desistência, o cancelamento de uma inscrição somente poderá ser realizado mediante solicitação por e-mail (atendimento@startse.com).</p>
        <p>2.2. <u>Solicitação de cancelamento e reembolso para cursos online.</u> Poderá ser solicitado o cancelamento da inscrição em uma Imersão Internacional no prazo de até 7 (sete) dias após o pagamento. Contudo, após passado o prazo de 7 (sete) dias, na hipótese de cancelamento em até 30 (trinta) dias antes da Imersão Internacional presencial, será cobrada multa de 50% (cinquenta por cento) do valor total acrescido de impostos. Na hipótese de cancelamento em até 15 (quinze) dias antes da Imersão Internacional, será cobrada multa de 70% (setenta por cento) do valor total acrescido de impostos. Na hipótese de cancelamento em até 7 (sete) dias antes da Imersão Internacional, não haverá qualquer reembolso e, caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente, independente de solicitação de cancelamento.</p>
        <p>2.2.1. A ausência ou atraso do Participante dia do na Imersão Internacional ou em um dos dias, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de inscrição.</p>
        <p>2.3.1. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a emissão do respectivo documento.</p>
        <p>2.3. <u>Solicitação de reagendamento de Imersões Internacionais por parte do cliente.</u> Pode ser solicitado o reagendamento da inscrição em até 30 dias antes da realização da Imersão Internacional, desde que seja aproveitada à mesma Imersão Internacional em outra data ou ainda a inscrição em outra Imersão Internacional de igual valor, sendo que a ação está limitada a 3 reagendamentos. Em nenhuma hipótese serão reembolsadas despesas extras do participante, como passagens ou hospedagem.</p>
        <p>2.3.1. Caso o Cliente opte por uma Imersão Internacional de maior valor que o crédito, o referido Cliente deverá realizar o pagamento da diferença para efetivar a inscrição.</p>
        <p>2.3.2. O Crédito será aplicado para Imersões Internacionais, não podendo ser aplicado para cursos /e ou evento em Território Nacional.</p>
        <p>2.4. <u>Reagendamento ou cancelamento de Imersões Internacionais por parte da <strong>STARTSE</strong>.</u> A <strong>STARTSE</strong> poderá cancelar ou adiar a Imersão Internacional com aviso prévio de, no mínimo, 20 (vinte) dias de antecedência. Nesse caso, caberá ao Participante a escolha de reembolso integral do valor pago ou a inscrição na mesma Imersão Internacional em outra data ou ainda a inscrição em outra Imersão Internacional de igual valor. Em nenhuma hipótese serão reembolsadas despesas extras do participante, como passagens ou hospedagem.</p>
        <p>2.4.1. A ausência ou atraso do Participante no dia do curso presencial ou em um dos dias, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de inscrição.</p>
        <p>2.5. Reagendamento ou cancelamento de cursos presenciais e eventos por parte da <strong>STARTSE</strong>. A <strong>STARTSE</strong> poderá cancelar ou adiar o curso presencial e/ou evento com aviso prévio de, no mínimo, 7 (sete) dias de antecedência. Nesse caso, caberá ao Participante a escolha do reembolso integral do valor pago ou a inscrição no mesmo curso e/ou evento em outra data ou ainda a inscrição em outro curso e/ou evento de igual valor. Em nenhuma hipótese será reembolsado despesas extras do participante, como passagens ou hospedagem.</p>
        <p>2.5.1. A <strong>STARTSE</strong> poderá cancelar ou adiar a Imersão Internacional com aviso prévio de, no mínimo, 20 (vinte) dias de antecedência. Nesse caso, caberá ao Participante a escolha do reembolso integral do valor pago ou a inscrição na mesma Imersão Internacional em outra data ou ainda a inscrição em outro Imersão Internacional de igual valor. Em nenhuma hipótese serão reembolsadas despesas extras do participante, como passagens ou hospedagem.</p>
        <p>2.6. Reembolso de pagamentos duplicados. Caso o Participante tenha pago duas vezes uma mesma Imersão Internacional, a <strong>STARTSE</strong> irá fazer o reembolso do valor duplicado após a confirmação no meio de pagamento utilizado.</p>
        <p>2.7. Procedimento para solicitação de cancelamento e reembolso. O Participante deverá solicitar o cancelamento e reembolso através do e-mail atendimento@startse.com ou demais canais de atendimento disponíveis na plataforma da <strong>STARTSE</strong>. Se o cancelamento for aprovado pela <strong>STARTSE</strong>, o reembolso ocorrerá dentro de até 7 (sete) dias úteis.</p>

        <p id="3"><strong>3. DISPOSIÇÕES GERAIS:</strong></p>
        <p>3.1. O Participante declara ter recebido a presente Política de Matrícula, Cancelamento e Reembolso pela internet, lido e compreendido seu inteiro teor, redigido em linguagem clara e consciente dos direitos e condicionantes que limitam o cumprimento do seu objeto, anuindo com todos os seus termos, tendo em vista que o prosseguimento e finalização se dará pela internet.</p>
        <p>E, por estarem assim, justas e contratadas, o Participante adere eletronicamente a esta Política de Matrícula, Cancelamento e Reembolso.</p>

        <p style={{ marginTop: 32 }}>São Paulo, 14 de Janeiro de 2020.</p>
        <p><strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong></p>
      </Content>

      <BackToTop />
    </Container>
  )
}
