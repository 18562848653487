import styled from "styled-components"

export const CardGroup = styled.div`
  max-width: 90%;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1300px;
  }

  & .MuiPaper-root.MuiCard-root {
    margin: 0 auto 32px;

    @media screen and (min-width: 1024px) {
      margin: 0 32px 64px;
    }
  }
`

