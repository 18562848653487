import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function General() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Imersões internacionais regulares e/ou segmentadas</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Imersões internacionais exclusivas e corporativas</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Cursos presenciais, cursos online transmitidos ao vivo, eventos e workshops;</span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span><strong>4.</strong> Cursos online</span>
            </a>
          </li>

          <li>
            <a href="#5">
              <span><strong>5.</strong> Disposições gerais</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Políticas Gerais de Cancelamento e Remarcação</h1>
        <h3>Esta política é vigente apenas para compras efetuadas até 14/05/2023.<br />
          Para compras efetuadas a partir de 15/05/2023, <a href={'https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao-v2'}>acesse este link.</a></h3>
        <p>A presente política tem por objetivo informar sobre as regras estabelecidas para as matrículas, reagendamentos, cancelamentos e reembolsos relacionados à todos os Eventos Presenciais (tais como Cursos Presenciais, Workshops, Eventos, Programas de Imersões Executivas Internacionais (“Eventos Presenciais”), bem como à todos os Cursos Online (“Cursos Online”), cujas inscrições se dão no site da <strong>STARTSE</strong> ou através de seus canais de atendimento e vendas, com amparo no artigo 49 da Lei nº 8.078/1990 (“Política”).</p>
        <p>O Participante que se inscrever, e/ou o Contratante que inscrever seus colaboradores ou convidados, nos Eventos Presenciais e/ou Cursos Online da <strong>STARTSE</strong>, atesta que leu integralmente e está de acordo com a presente Política, com os termos de uso e política de privacidade referentes à mesma.</p>

        <p id="1"><strong>1. IMERSÕES INTERNACIONAIS REGULARES E/OU SEGMENTADAS:</strong></p>
        <p>1.1. Este capítulo se refere aos Programas de Imersões Executivas Internacionais, cuja compra é feita de maneira individual e realizada em grupos abertos, podendo ser segmentado ou não, tais como: “Missão Vale do Silício, Missão Vale do Silício Agro, Missão China, Missão China Varejo” e similares, por exemplo.</p>
        <p>1.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida comprovação de pagamento pela instituição financeira competente.</p>
        <p>1.1.2. <u>Da Troca de Participantes:</u>  A troca de Participante deverá ser realizada diretamente na plataforma da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias de antecedência da realização do Programa de Imersão Internacional.</p>
        <p>1.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte do Programa de Imersão Executiva Internacional e, ainda, poderá prejudicar a experiência do restante do grupo, por conta de motivos operacionais.</p>
        <p>1.1.3. Do Reagendamento, Cancelamento e Reembolso:</p>
        <p>1.1.3.1. <u>Por parte do Participante:</u></p>
        <p>a) Reagendamento:</p>
        <p>i. O Participante poderá solicitar o reagendamento de sua inscrição em até 30 (trinta) dias antes da realização do Programa de Imersão Executiva Internacional adquirido, desde que o mesmo seja aproveitado em outra data e que o novo Programa de Imersão Executiva Internacional selecionada seja de igual valor, sendo que esta ação está limitada a 2 (dois) reagendamentos no prazo de até 1 (um) ano;</p>
        <p>ii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>;</p>
        <p>iii. Caso o Participante opte por um Programa de Imersão Executiva Internacional de maior valor que o crédito decorrente do reagendamento em questão, o Participante deverá realizar o pagamento da diferença para efetivar a nova inscrição no Programa de Imersão Executiva Internacional desejado; e</p>
        <p>iv. O crédito será aplicado para Imersões Internacionais, não podendo ser aplicado para Cursos Presenciais, Eventos e/ou Workshops em território nacional.</p>
        <p>b) Cancelamento:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso ao Participante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Programa de Imersão Executiva Internacional e em até 45 (quarenta e cinco) dias antes do início do referido Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso será de 50% (cinquenta por cento) do valor total acrescido de impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso será de 30% (trinta por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 15 (quinze) dias antes do início do Programa de Imersão Executiva Internacional, o cancelamento será realizado, contudo, não haverá qualquer reembolso. Caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Programa de Imersão Executiva Internacional, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a emissão do respectivo documento; e</p>
        <p>vii. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso acontecerá no 20° dia do mês subsequente.</p>
        <p>1.1.3.2. <u>Do Grupo Mínimo:</u></p>
        <p>i. Caso o número de Participantes, após eventuais cancelamentos, venha a ser inferior a 15 (quinze) participantes, a <strong>STARTSE</strong> reserva-se ao direito de não realizar o Programa de Imersão Executiva Internacional, sem incidência de qualquer multa à <strong>STARTSE</strong> neste sentido; e</p>
        <p>ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte do Participante, sendo que as regras de reembolso serão orientadas pela data de solicitação de cancelamento do 15º (décimo quinto) Participante remanescente do grupo exclusivo.</p>
        <p>1.1.3.3. <u>Por parte da <strong>STARTSE</strong>:</u></p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão Executiva Internacional, de maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:</p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> não incorrerá incorrerá em nenhuma multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Programa de Imersão Executiva Internacional e em até 10 (dez) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor da Imersão Internacional reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Programa de Imersão Executiva Internacional e até o dia do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor do Programa de Imersão Executiva Internacional reagendado ou cancelado, a ser paga ao Participante.</p>
        <p>ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo Programa de Imersão Executiva Internacional reagendado e/ou cancelado ou uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser realizado;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Imersões Internacionais decorrentes de caso fortuito, motivados de força maior, causas extremas, ou qualquer outro fato alheio à <strong>STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto, nestes casos, a <strong>STARTSE</strong> possibilitará ao Participante optar pelo reembolso integral do valor pago pelo Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou realizar uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser realizado.</p>

        <p id="2"><strong>2. IMERSÕES INTERNACIONAIS EXCLUSIVAS E CORPORATIVAS:</strong></p>
        <p>2.1. Este capítulo trata dos Programas de Imersões Executivas Internacionais compradas de maneira individual em grupos exclusivos, podendo ser segmentado ou não, bem como das adquiridas por pessoa jurídica (“Contratante”) para colaboradores e/ou convidados (ex: clientes e fornecedores);</p>
        <p>2.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida comprovação de pagamento pela instituição financeira, e no caso das corporativas, somente será reservada e concretizada após a devida assinatura do contrato de prestação de serviço entre a <strong>STARTSE</strong> e a Contratante.</p>
        <p>2.1.1.1. No caso dos Participantes serem convidados da Contratante, os mesmos estarão submetidos ao contrato estabelecido e celebrado entre a Contratante e a <strong>STARTSE</strong>.</p>
        <p>2.1.2. <u>Da Troca de Participantes:</u> deverá ser alterada diretamente na plataforma (visualizar compra > participantes > alterar) com até A troca de Participante deverá ser realizada diretamente na plataforma da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias de antecedência da realização do Programa de Imersão Internacional.</p>
        <p>2.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte do Programa de Imersão Executiva Internacional Programa de Imersão Executiva Internacional e, ainda, poderá prejudicar a experiência do restante do grupo, por conta de motivos operacionais.</p>
        <p>2.1.3. <u>Do Reagendamento, Cancelamento e Reembolso:</u></p>
        <p>2.1.3.1. <u>Reagendamento:</u></p>
        <p>i. O grupo, de forma unificada (100% dos participantes), poderá solicitar reagendamento da data do Programa de Imersão Executiva Internacional do grupo, sem multas, em até 60 dias antes da realização da mesma;</p>
        <p>ii. Após o período acima citado, serão aplicadas as regras de cancelamento (ver cláusula 2.1.3.3);</p>
        <p>iii. A nova data para o grupo exclusivo será definida em comum acordo com a <strong>STARTSE</strong>; e</p>
        <p>iv. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>.</p>
        <p>2.1.3.2. <u>Reagendamento por Parte do Participante:</u></p>
        <p>i. Por tratar-se de um grupo exclusivo, o Participante não poderá solicitar reagendamento para outro Programa de Imersão Executiva Internacional e deverá solicitar o cancelamento de sua inscrição no grupo exclusivo, sabendo que poderá não cumprir a regra do grupo mínimo de 15 participantes (cláusula 2.1.3.4); e</p>
        <p>ii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>.</p>
        <p>2.1.3.3. <u>Cancelamento por Parte do Participante ou Contratante:</u></p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso ao Participante ou Contratante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Programa de Imersão Executiva Internacional e em até 45 (quarenta e cinco) dias antes do início do mesmo, o cancelamento será confirmado e o reembolso será de 50% (cinquenta por cento) do valor total acrescido de impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso será de 30% (trinta por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 15 (quinze) dias antes do início do Programa de Imersão Executiva Internacional, o cancelamento será realizado, contudo, não haverá qualquer reembolso. Caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Programa de Imersão Executiva Internacional, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a emissão do respectivo documento; e</p>
        <p>vii. Se a solicitação de cancelamento for aprovado pela <strong>STARTSE</strong>, o Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso acontecerá no 20° dia do mês subsequente.</p>
        <p>2.1.3.4. <u>Grupo Mínimo:</u></p>
        <p>i. Caso o número de Participantes do grupo exclusivo, após eventuais cancelamentos, venha a ser inferior a 15 (quinze) participantes, a <strong>STARTSE</strong> reserva-se ao direito de não realizar o Programa de Imersão Executiva Internacional, sem incidência de qualquer multa à <strong>STARTSE</strong> neste sentido; e</p>
        <p>ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte do Participante, sendo que as regras de reembolso serão orientadas pela data de solicitação de cancelamento do 15º (décimo quinto) Participante remanescente do grupo exclusivo.</p>
        <p>2.1.3.5. <u>Reagendamento e/ou Cancelamento por parte da <strong>STARTSE</strong>:</u></p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão Executiva Internacional, de maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:</p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> não incorrerá incorrerá em nenhuma multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Programa de Imersão Executiva Internacional e em até 10 (dez) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor do Programa de Imersão Executiva Internacional reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Programa de Imersão Executiva Internacional e até o dia do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor do Programa de Imersão Executiva Internacional reagendado ou cancelado, a ser paga ao Participante.</p>
        <p>ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser realizada;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Programas de Imersões Executivas Internacionais decorrentes de caso fortuito, motivados de força maior, causas extremas, ou qualquer outro fato alheio à <strong>STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto, nestes casos, a <strong>STARTSE</strong> possibilitará ao Participante optar pelo reembolso integral do valor pago pelo Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou realizar uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser realizada.</p>

        <p id="3"><strong>3. CURSOS PRESENCIAIS, CURSOS ONLINE TRANSMITIDOS AO VIVO, EVENTOS E WORKSHOP:</strong></p>
        <p>3.1. Este capítulo se refere aos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops.</p>
        <p>3.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida comprovação de pagamento pela instituição financeira competente.</p>
        <p>3.1.2. <u>Da Troca de Participantes:</u> A troca de Participante deverá ser realizada diretamente na plataforma da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias de antecedência da realização dos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops.</p>
        <p>3.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte dos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops.</p>
        <p>3.1.3. <u>Do Reagendamento, Cancelamento e Reembolso:</u></p>
        <p>3.1.3.1. <u>Por parte do Participante:</u></p>
        <p>a) Reagendamento:</p>
        <p>i. O Participante poderá solicitar o reagendamento de sua inscrição em até 30 (trinta) dias antes da realização Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops adquiridos, desde que a mesma seja aproveitada em outra data e que o novo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop selecionado seja de igual valor, sendo que esta ação está limitada a 2 (dois) reagendamentos no prazo de até 1 (um) ano;</p>
        <p>ii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>;</p>
        <p>iii. Caso o Participante opte por um Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de maior valor que o crédito decorrente do reagendamento em questão, o Participante deverá realizar o pagamento da diferença para efetivar a nova inscrição no Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop desejado; e</p>
        <p>iv. O crédito será aplicado para Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, não podendo ser aplicado para Programas de Imersões Executivas Internacionais.</p>
        <p>b) Cancelamento:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso ao Participante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e em até 45 (quarenta e cinco) dias antes do início do referido Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso será de 50% (cinquenta por cento) do valor total acrescido de impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso será de 30% (trinta por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 15 (quinze) dias antes do início da imersão, o cancelamento será realizado, contudo, não haverá qualquer reembolso. Caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a emissão do respectivo documento; e</p>
        <p>vii. Se a solicitação de cancelamento for aprovado pela <strong>STARTSE</strong>, o Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso acontecerá no 20° dia do mês subsequente.</p>
        <p>3.1.3.2. <u>Por parte da <strong>STARTSE</strong>:</u></p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, de maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:</p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o reembolso ao Participante será integral e a <strong>STARTSE</strong> não incorrerá incorrerá em nenhuma multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e em até 10 (dez) dias antes do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e até o dia do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendado ou cancelado, a ser paga ao Participante.</p>
        <p>ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendado e/ou cancelado ou uma nova inscrição em outro Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de igual valor a ser realizado;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop decorrentes de caso fortuito, motivados de força maior, causas extremas, ou qualquer outro fato alheio à <strong>STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto, nestes casos, a <strong>STARTSE</strong> possibilitará ao Participante optar pelo reembolso integral do valor pago pelo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendada e/ou cancelada ou realizar uma nova inscrição em outro Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de igual valor a ser realizada.</p>

        <p id="4"><strong>4. CURSOS ONLINE:</strong></p>
        <p>4.1. Este capítulo se refere aos Cursos Online.</p>
        <p>4.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida comprovação de pagamento pela instituição financeira competente.</p>
        <p>4.1.2. Do Cancelamento e Reembolso por parte do Participante:</p>
        <p>a) Cancelamento:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Online, o Participante terá o direito de solicitar o cancelamento da matrícula, bem como a devolução integral do valor pago, descontados os encargos administrativos. A partir da aprovação da solicitação de cancelamento pela <strong>STARTSE</strong>, o reembolso ao Participante ocorrerá dentro de até 7 (sete) dias úteis; e</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Curso Online, o Participante terá o direito de solicitar o cancelamento da matrícula, no entanto, não haverá qualquer tipo de reembolso ao Participante do valor pago pelo Curso Online.</p>
        <p>4.1.2.1. <u>Suspensão e/ou Cancelamento por parte da <strong>STARTSE</strong>:</u></p>
        <p>i. A <strong>STARTSE</strong> poderá suspender ou cancelar a disponibilidade de um Curso Online, de maneira imotivada, ou seja, a seu único exclusivo critério, mediante o reembolso integral ao Participante, sendo que a <strong>STARTSE</strong> não incorrerá incorrerá em nenhuma multa; e</p>
        <p>ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo Curso Online suspenso e/ou cancelado ou uma nova inscrição em outro Curso Online de igual valor a que esteja disponível.</p>

        <p id="5"><strong>5. DISPOSIÇÕES GERAIS:</strong></p>
        <p>5.1. <u>Do Procedimento para Solicitação de Reagendamento, Cancelamento e/ou Reembolso:</u> O Participante e/ou Contratante deverá solicitar o reagendamento, cancelamento e/ou reembolso através do e-mail atendimento@startse.com ou demais canais de atendimento disponíveis na plataforma da <strong>STARTSE</strong>;</p>
        <p>5.2. <u>Dos Pagamentos Duplicados:</u> Caso o Contratante e/ou Participante tenha pago duas vezes um mesmo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e/ou Curso Online, a <strong>STARTSE</strong> irá realizar o reembolso do valor duplicado após a confirmação no meio de pagamento utilizado;</p>
        <p>5.3. O Participante e/ou o Contratante declara ter recebido a presente Política pela internet, lido e compreendido seu inteiro teor, redigido em linguagem clara e consciente dos direitos e condicionantes que limitam o cumprimento do seu objeto, anuindo com todos os seus termos.</p>

        <p style={{ marginTop: 32 }}>São Paulo, 02 de Dezembro de 2020.</p>
        <p><strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong></p>
      </Content>

      <BackToTop />
    </Container>
  )
}
