import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function General() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Minha autorização</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Exibição pela StartSe</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Sem remunerações</span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span><strong>4.</strong> Concessão do uso da imagem</span>
            </a>
          </li>

          <li>
            <a href="#5">
              <span><strong>5.</strong> Leitura e compreensão</span>
            </a>
          </li>

          <li>
            <a href="#6">
              <span><strong>6.</strong> Assinatura eletrônica</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Termo de Autorização de Uso de Imagem e Voz</h1>
        <p id="1">1. Eu <strong>​AUTORIZO</strong> a captação, fixação, edição e utilização de minhas imagens e voz para serem inseridas e utilizados nos meios de comunicação da ​<strong>STARTSE INFORMAÇÕES E SISTEMA LTDA​</strong>, inscrita no CNPJ/MF sob o no. 24.554.736/0001-70, com sede na Rua Capitão Antônio Rosa, nº 409, Jardim Paulistano, São Paulo/SP, CEP 01.443​010 ("​<strong>STARTSE​</strong>"), ​e para a veiculação na internet, podendo gravar, editar, reproduzir e difundir no Brasil e exterior, sem limitação de tempo ou de número de vezes, por intermédio de quaisquer meios de comunicação e mídia e todos os materiais institucionais produzidos pela ​<strong>STARTSE</strong> com as imagens e os sons captados por ocasião dos encontros, cursos presenciais, eventos, workshops e missões, especificamente, mas não limitados, os sons e as imagens internas, externas, entrevistas, palestras e plateia, por prazo indeterminado ("Autorização​").</p>
        <p id="2">2. <strong>RECONHEÇO</strong> ​expressamente que a <strong>​STARTSE​</strong>, poderá, a seu exclusivo critério, utilizar minhas imagens e sons captados livremente, bem como seus extratos, trechos ou partes, podendo, exemplificativamente, adaptá-la para fins de composição de qualquer produto ligado à <strong>STARTSE​</strong>, exibi-la e comercializá-la ou alugá-la ao público em qualquer suporte material existente, promover ações de merchandising ou veicular propaganda, disseminá-la através da Internet e/ou telefonia, fixa e/ou móvel, ceder os direitos autorais sobre as imagens e sons cuja utilização foi autorizada através deste Termo à terceiros, para qualquer espécie de utilização, ou, ainda, dar-Ihe qualquer outra utilização que proporcione à ​<strong>STARTSE</strong> alguma espécie de vantagem econômica.</p>
        <p id="3">3. <strong>AUTORIZO</strong> também, de livre e espontânea vontade, a cessão de direitos da veiculação das imagens e sons, pelo que concordo em não receber para tanto qualquer tipo de remuneração, bem como declaro jamais reclamar a qualquer título acerca da utilização.</p>
        <p id="4">4. <strong>DECLARO</strong> que a presente Autorização é concedida a título gratuito, universal, em caráter total, definitivo, irrevogável e irretratável, abrangendo o uso das imagens, sons e vídeos cedidos por mim, obrigando as partes por si, seus herdeiros e sucessores a qualquer título, ficando eleito o foro da Comarca de São Paulo para dirimir quaisquer dúvidas oriundas deste Termo.</p>
        <p id="5">5. <strong>DECLARO</strong> ainda ter recebido o presente Termo pela internet, lido e compreendido seu inteiro teor, anuindo com todos os seus termos, tendo em vista que o prosseguimento e finalização da celebração ocorrerá pela internet.</p>
        <p id="6">6. Por esta ser a expressão da minha vontade declaro que ​<strong>AUTORIZO</strong> o uso acima descrito sem que nada haja a ser reclamado a título de direitos conexos à minha imagem e voz ou a qualquer outro, e assino eletronicamente o presente.</p>
      </Content>

      <BackToTop />
    </Container>
  )
}
