import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'fixed',
      width: '100%',
      top: 0,
    },
    navbar: {
      backgroundColor: '#FFF',
      alignItems: 'center',
      boxShadow: '0 3px 7px rgba(39, 39, 39, 0.2)'
    },
    toolBar: {
      minHeight: 46,
    },
    logo: {
      flexGrow: 1,
      flexWrap: 'no-wrap',
    },
  }),
)

export default function SearchAppBar() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar className={classes.navbar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.logo}>
            <Link to='/'>
              <img src="https://startse-landings.s3.us-east-2.amazonaws.com/docs/svg/blue-logo-startse.svg" alt="Logo StartSe na cor azul" width="150" />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
