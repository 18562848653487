import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

import { useHistory } from 'react-router-dom'

export default function Privacy () {
  const history = useHistory();

  function hanbleRegulation () {
    history.push('/politica-do-regulamento')
  }
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#rights">
              <span><strong>1.</strong> Direitos</span>
            </a>
          </li>

          <li>
            <a href="#information">
              <span><strong>2.</strong> Informações e dados coletados</span>
            </a>
          </li>

          <li>
            <a href="#terms">
              <span><strong>3.</strong> Termos Gerais</span>
            </a>
          </li>

          <li>
            <a href="#data">
              <span><strong>4.</strong> Dados pessoais coletados</span>
            </a>
          </li>

          <li>
            <a href="#sharing">
              <span><strong>5.</strong> Compartilhamento dos dados pessoais coletados</span>
            </a>
          </li>

          <li>
            <a href="#cookies">
              <span><strong>5.</strong> Cookies</span>
            </a>
          </li>

          <li>
            <a href="#access">
              <span><strong>6.</strong> Acesso e correção dos dados pessoais</span>
            </a>
          </li>

          <li>
            <a href="#notification">
              <span><strong>7.</strong> Notificação de modificações</span>
            </a>
          </li>

          <li>
            <a href="#comunication">
              <span><strong>8.</strong> Comunicação</span>
            </a>
          </li>

          <li>
            <a href="#safe">
              <span><strong>9.</strong> Segurança de dados pessoais</span>
            </a>
          </li>

          <li>
            <a href="#general">
              <span><strong>10.</strong> Disposições gerais</span>
            </a>
          </li>

          <li>
            <a href="#law">
              <span><strong>11.</strong> Lei aplicável e jurisdição</span>
            </a>
          </li>
          <li>
            <a href="#intellectualProperty">
              <span><strong>12.</strong> Propriedade Intelectual</span>
            </a>
          </li>
          <li onClick={() => hanbleRegulation()}>
            <a href="#regulation">
              <span><strong>13.</strong>Regulamento da Promoção</span>
            </a>
          </li>

        </ul>
      </NavbarGroup>

      <Content>
        <h1>Política de Privacidade</h1>
        <p>Seja bem-vindo(a)!</p>
        <p><strong>Nós</strong> somos a <strong>StartSe</strong>.</p>
        <p>Ficamos muito felizes por te receber na <strong>StartSe</strong>!</p>
        <p>Para que <strong>Você</strong> possa entender como faremos o tratamento dos dados que coletaremos, disponibilizamos um breve resumo a seguir.</p>

        <p id="rights" style={{ paddingTop: 64 }}><strong>DIREITOS DOS TITULARES DE DADOS PESSOAIS</strong></p>
        <p><strong>Vocês</strong> são os titulares (pessoas naturais) de dados pessoais a quem se referem os dados pessoais que serão coletados por <strong>Nós</strong>.</p>
        <p>A legislação brasileira concede certos direitos à Pessoa Física quanto a seus dados pessoais. Dessa forma, <strong>Nós</strong> garantimos transparência e controle de acesso aos titulares de dados pessoais, proporcionando-lhes a melhor experiência possível durante a utilização da Plataforma. Os direitos do titular dos dados pessoais são os seguintes:</p>
        <p>a) Direito de confirmação da existência de tratamento - Direito de solicitar a confirmação da existência de tratamento aos seus dados pessoais através de informações claras sobre a origem dos dados, a inexistência de registro, os critérios utilizados e a finalidade do tratamento;</p>
        <p>b) Direito de acesso - direito de ser informado e solicitar acesso aos dados pessoais tratados por <strong>Nós</strong>;</p>
        <p>c) Direito de retificação - direito de solicitar que Nós alteremos ou atualizemos os seus dados pessoais quando os mesmos estiverem incorretos ou incompletos;</p>
        <p>d) Direito de exclusão - direito de solicitar a eliminação de seus dados pessoais coletados por <strong>Nós</strong>;</p>
        <p>e) Direito de restrição - direito de solicitar que Nós deixemos, temporária ou permanentemente, de processar todos ou alguns dos seus dados pessoais;</p>
        <p>f) Direito de oposição - direito de se opor, a qualquer momento, (i) ao processamento de seus dados pessoais por motivos relacionados à sua situação particular e (ii) à manipulação dos seus dados pessoais para fins de marketing direto;</p>
        <p>g) Direito à portabilidade de dados - direito de solicitar a transmissão dos referidos dados pessoais para utilização em serviços de terceiros;</p>
        <p>h) Direito à cópia - direito de solicitar uma cópia dos seus dados pessoais em formato eletrônico</p>

        <p id="information" style={{ paddingTop: 64 }}><strong>INFORMAÇÕES / DADOS PESSOAIS COLETADOS E SUA FINALIDADE</strong></p>
        <p>Na tabela abaixo exibimos quais dados serão coletados, sua categoria e finalidade.</p>

        <table>
          <tr>
            <th>Dado coletado</th>
            <th>Classificação</th>
          </tr>
          <tr>
            <td>Nome completo; <br />E-mail; <br />CPF;<br />Telefone; <br />Empresa onde trabalha; <br />Cargo; <br />Tamanho da empresa; <br />Segmento; <br />Departamento; <br />Interesses; <br />Cidade; <br />Estado.</td>
            <td>Dados cadastrais</td>
          </tr>
        </table>

        <p>Os dados coletados serão utilizados com as seguintes finalidades:</p>

        <table>
          <tr>
            <th>Dado coletado</th>
            <th>Finalidade</th>
          </tr>
          <tr>
            <td>Dados cadastrais</td>
            <td>Identificação; <br />Enviar comunicações a <strong>Você</strong>.</td>
          </tr>
        </table>

        <p>Para que todas as informações sejam corretamente compreendidas, nós apresentamos nossa Política de Privacidade de forma transparente e acessível, como poderá ser visto a seguir:</p>
        <p><strong>DESTACAMOS QUE É EXTREMAMENTE IMPORTANTE A COMPREENSÃO COMPLETA E A LEITURA TOTAL DESTE DOCUMENTO.</strong></p>

        <p id="terms" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- TERMOS GERAIS -</strong></p>
        <p><strong>1.1. <u>Geral</u>.</strong> Esta <strong>Política de Privacidade</strong> foi concebida para comunicar as nossas práticas relativas ao tratamento (inclui coleta, armazenamento, utilização, processamento e compartilhamento) de seus dados pessoais por <strong>Nós</strong> em todos os produtos como cursos, conferências e programas de imersão organizados pela <strong>StartSe</strong>. A <strong>StartSe</strong> é de titularidade da <strong>STARTSE INFORMACOES E SISTEMAS S/A</strong>, inscrita no CNPJ sob o nº 24.554.736/0002-50, com sede na Rua Capitão Antônio Rosa, 409, Pinheiros, na cidade de São Paulo/SP, CEP 01443-010, que, para todos os fins, será considerada a controladora dos dados.</p>
        <p><strong>1.2. <u>O que são Dados Pessoais</u>.</strong> Os dados pessoais são as informações que podem ser usadas para identificar <strong>Você</strong> individualmente.</p>
        <p><strong>1.3. <u>Autorização de Coleta de Informações</u>.</strong> Ao se inscrever em <strong>Nossos</strong> cursos, conferências e programas de imersão, <strong>Você</strong> deverá aceitar e autorizar a coleta de informações através do botão “li e concordo com os termos", nos termos da presente política de privacidade.</p>
        <p><strong>1.3.1. Você</strong> poderá desistir de consentir com as disposições desta Política de Privacidade, a qualquer momento, bastando para tal que notifique a <strong>StartSe</strong> através do e-mail atendimento@startse.com.</p>
        <p><strong>1.4. <u>Regulamentação</u></strong>. <strong>Nós</strong> atuamos de acordo com a legislação brasileira, atendendo, inclusive e especialmente, com os termos da Lei nº. 12.965/2014 (o Marco Civil da Internet) e da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados) e demais leis que venham a ser aplicadas.</p>
        <p><strong>1.5. <u>Contato</u></strong>. Em caso de dúvida ou sugestões sobre a Política de Privacidade ou qualquer outra informação, <strong>Você</strong> poderá entrar em contato com os administradores da <strong>StartSe</strong> através do e-mail atendimento@startse.com.</p>
        <p><strong>1.6. <u>Controle de Informações</u></strong>. Esta Política descreve quais os dados pessoais podem ser coletados de <strong>Vocês</strong> ao se inscreverem em <strong>Nossos</strong> cursos, conferências e programas de imersão, como essas informações podem ser utilizadas e as precauções tomadas contra o acesso ou uso destas informações de forma não autorizada.</p>

        <p id="data" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- DADOS PESSOAIS COLETADOS -</strong></p>
        <p><strong>2.1. Nós</strong> poderemos coletar, armazenar e utilizar as seguintes informações e/ou dados pessoais de <strong>Vocês</strong>:</p>
        <p>a) Nome completo;</p>
        <p>b) E-mail;</p>
        <p>c) CPF;</p>
        <p>d) Telefone;</p>
        <p>e) Empresa onde trabalha;</p>
        <p>f) Cargo;</p>
        <p>g) Tamanho da empresa;</p>
        <p>h) Segmento;</p>
        <p>i) Departamento;</p>
        <p>j) Interesses;</p>
        <p>k) Cidade;</p>
        <p>l) Estado.</p>
        <p><strong>2.2. Nós</strong> também poderemos coletar, armazenar e utilizar as seguintes informações:</p>
        <p>a) Os detalhes de acesso à <strong>StartSe</strong> e dos recursos que <strong>Você</strong> acessou;</p>
        <p>b) Informações do dispositivo de acesso utilizado, incluindo, por exemplo, modelo do hardware, sistema operacional e versão, nomes e versões dos arquivos, idioma de preferência, identificador exclusivo do dispositivo, identificadores de publicidade, número de série, informações referentes ao movimento do dispositivo e informações de rede;</p>
        <p>c) Informações de registro do servidor que podem incluir informações como endereço IP do dispositivo;</p>
        <p>d) Mapeamento de cliques no navegador, dados de navegação, estatísticos, demográficos, entre outros;</p>
        <p>e) Mapeamento de cliques nos aplicativos e dados de navegação.</p>
        <p><strong>2.3. <u>Aplicativos Nativos</u></strong>. Os aplicativos nativos podem armazenar dados de uso, imagens, vídeos, textos, senha de acesso ao sistema de biometria, os quais não são compartilhados <u>Conosco</u>.</p>

        <p id="sharing" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- COMPARTILHAMENTO DOS DADOS PESSOAIS COLETADOS -</strong></p>
        <p><strong>3.1. <u>Exceções para Compartilhamento de Dados Pessoais</u></strong>. <strong>Nós</strong> não divulgamos a terceiros qualquer dado pessoal fornecido por <strong>Vocês</strong>, através da <strong>Nossa</strong> plataforma, exceto:</p>
        <p>a) Casos em que <strong>Nós</strong> formos obrigados a divulgar ou partilhar os dados coletados, a fim de cumprir ordem judicial, ou para efeitos de prevenção de fraude ou outros crimes, bem como em resposta à solicitação de informações apresentada por autoridade competente, se entendermos que a divulgação está de acordo ou é exigida conforme as leis, regulamentos ou procedimentos processuais aplicáveis;</p>
        <p>b) Para proteger os direitos, bens ou a segurança de <strong>Nossa</strong> Plataforma;</p>
        <p>c) Com agentes da lei e/ou autoridades governamentais, se entender-se que suas ações são inconsistentes em relação às disposições em nossos termos de uso, ou para proteger os direitos, a propriedade ou a <strong>Nossa</strong> segurança, a de <strong>Vocês</strong> ou de outros;</p>
        <p>d) Mediante ação própria de <strong>Vocês</strong>;</p>
        <p>e) Nos casos de venda, parcial ou total, do negócio ou de seus ativos, ou como parte de qualquer reorganização ou reestruturação do negócio, fusão, cisão ou incorporação, de modo que <strong>Nós</strong> poderemos compartilhar as informações de <strong>Vocês</strong> com terceiros que façam parte dos respectivos negócios, tomando as medidas necessárias para garantir que os direitos de privacidade continuem a ser protegidos, conforme esta Política;</p>
        <p>f) Com os patrocinadores e coorganizadores dos cursos, conferências ou programas de imersão;</p>
        <p>g) Com os expositores das conferências virtuais, a partir do momento em que <strong>Você</strong> clicar em “visitar estande".</p>
        <p><strong>3.2. <u>Autorizações de Contato e Divulgação</u></strong>. <strong>Vocês</strong> autorizam a <strong>StartSe</strong> a contatá-los utilizando qualquer um dos meios de contato informados no ato de inscrição</p>

        <p id="cookies" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- COOKIES -</strong></p>
        <p><strong>4.1. <u>O que são os Cookies</u></strong>. Um cookie é um trecho de informação armazenado localmente no seu computador ou dispositivo, e que contém informação acerca das atividades deste na Internet.</p>
        <p><strong>4.2. <u>Cookies Utilizados</u></strong>. Os cookies podem ser usados de diferentes formas e modalidades. <strong>Nós</strong> poderemos utilizar:</p>
        <p>a) <u>Cookies de Desempenho</u>: Este tipo de cookie coleta informações anônimas sobre a forma como <strong>Vocês</strong> utilizam a Plataforma da <strong>StartSe</strong>, de forma a otimizá-la. As informações coletadas por estes cookies nunca contêm detalhes pessoais a partir dos quais seja possível identificá-lo.</p>
        <p>b) <u>Cookies Funcionais</u>: Estes cookies são usados por <strong>Nós</strong> por algumas de suas funcionalidades, buscando lhe oferecer uma melhor experiência durante a utilização da Plataforma.</p>
        <p>c) <u>Cookies publicitários</u>: Estes cookies coletam informações sobre os hábitos de navegação, buscando tornar a publicidade mais relevante para <strong>Você</strong>.</p>
        <p><strong>4.3. <u>Acesso aos Cookies</u></strong>. O acesso aos cookies termina assim que <strong>Você</strong> fecha o navegador. É dada a <strong>Você</strong> a possibilidade de aceitar ou recusar os cookies.</p>
        <p><strong>4.4. <u>Consentimento para Utilização de Cookies</u></strong>. É necessária sua aceitação, antes de iniciada a seção, para que os Cookies sejam utilizados.</p>
        <p><strong>4.5. <u>Opção sem Cookies</u></strong>. O titular pode optar por recusar o uso de cookies. Se Você optar por não os aceitar, o seu acesso à maior parte das funcionalidades da plataforma não será comprometido. Contudo, o titular poderá não conseguir usar plenamente os serviços oferecidos.</p>

        <p id="access" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- ACESSO E CORREÇÃO DE DADOS PESSOAIS -</strong></p>
        <p><strong>5.1. <u>Acesso e Retificação de Dados Pessoais</u></strong>. Vocês têm direito a acessar os seus dados pessoais sob <strong>Nossa</strong> posse, contatando os administradores através do e-mail atendimento@startse.com, que será respondido em horário comercial, no prazo de 15 (quinze) dias, cumulada com descrição da forma, finalidade e duração do tratamento dos dados pessoais, que poderá ser enviada por e-mail ou através de carta, de acordo com o previsto no artigo 9º da LGPD.</p>
        <p><strong>5.2. <u>Exclusão</u></strong>. Você tem direito de solicitar a exclusão dos seus dados pessoais armazenados pela StartSe, a qualquer momento, salvo nos casos em que houver obrigação legal ou decisão judicial para a manutenção do armazenamento dos dados, de acordo com o disposto nos artigos 18, XVI, e 16 da LGPD.</p>
        <p><strong>5.3. <u>Incorreção de Informações</u></strong>. É responsabilidade de <strong>Vocês</strong> manterem suas informações atualizadas, sendo certo que, em caso de incorreção, <strong>Nós</strong> poderemos atualizá-las ou excluí-las, excetuando-se os casos de necessidade de manutenção para fins comerciais legítimos ou jurídicos.</p>
        <p><strong>5.4. <u>Medidas Necessárias de Proteção</u></strong>. Nós tomamos as medidas de segurança necessárias para proteger seus dados pessoais contra a perda, utilização indevida, acesso, divulgação, alteração ou destruição não autorizados.</p>
        <p><strong>5.5. <u>Proteção de Senhas</u></strong>. <strong>Vocês</strong> são igualmente responsáveis por tomar medidas cabíveis destinadas a proteger as suas senhas, nomes de usuário e outros recursos de acesso especiais ao cadastro pessoal na <strong>StartSe</strong>.</p>

        <p id="notification" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- NOTIFICAÇÃO DE MODIFICAÇÕES NA POLÍTICA DE PRIVACIDADE -</strong></p>
        <p><strong>6.1. <u>Alterações na Política de Privacidade</u></strong>. <strong>Nós</strong> podemos alterar a Política de Privacidade da Plataforma de tempos em tempos. O uso de informações coletadas agora está sujeito à Política de Privacidade em vigor. Se <strong>Nós</strong> realizarmos mudanças na forma como utiliza as informações pessoais, notificaremos <strong>Vocês</strong> através do envio de e-mail.</p>
        <p><strong>6.2. <u>Ajustes Não Notificados</u></strong>. Pequenos ajustes nesta Política de Privacidade podem ocorrer sem afetar significativamente as formas em que <strong>Nós</strong> tratamos as informações coletadas, de forma que estes não precisarão ser informados.</p>

        <p id="comunication" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- COMUNICAÇÃO -</strong></p>
        <p><strong>7.1. <u>Envio de Comunicações</u></strong>. Vocês, ao se cadastrarem, concordam que <strong>Nós</strong> enviemos para o seu e-mail notificações, publicidades, novidades sobre os serviços e informações importantes sobre a utilização dos produtos da <strong>StartSe</strong>.</p>
        <p><strong>7.1.1. <u>Opção por Não Recebimento</u></strong>. Ao receber um e-mail em nome da StartSe, haverá a possibilidade de optar por não mais recebê-los, utilizando da opção opt-out ou através de solicitação por e-mail.</p>
        <p><strong>7.2. <u>Política AntiSpam</u></strong>. A <strong>StartSe</strong> toma os cuidados necessários para evitar o envio não solicitado de e-mails.</p>
        <p><strong>7.3. Confidencialidade</strong>. É assegurado o máximo de confidencialidade no tratamento da lista de telefones e e-mails durante as tarefas regulares de <strong>Nossa</strong> administração.</p>

        <p id="safe" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- SEGURANÇA DE DADOS PESSOAIS -</strong></p>
        <p><strong>8.1. <u>Armazenamento</u></strong>. Os dados pessoais de sua conta e todas as informações da plataforma são armazenados e trafegam de forma segura, sendo que apenas os colaboradores autorizados por <strong>Nós</strong> podem ter acesso às suas informações pessoais, estando obrigatoriamente sujeitos aos deveres de confidencialidade e rigoroso respeito à sua privacidade nos termos desta Política.</p>
        <p><strong>8.2. <u>Segurança da Informação</u></strong>. Todas as transações da <strong>StartSe</strong> são criptografadas, sujeitas a backup de dados, ferramentas de monitoramento, políticas de segurança, controles de acesso para colaboradores, com softwares de segurança atualizados.</p>
        <p><strong>8.3. <u>Notificação</u></strong>. Se <strong>Nós</strong> tomarmos conhecimento de qualquer violação à segurança própria ou a empresas de hospedagem, incluindo invasões, vazamentos de dados ou qualquer outro incidente de segurança da informação, iremos notificar as autoridades nacionais, <strong>Vocês</strong>, se afetados, acerca dessa violação e iremos fornecer o máximo de detalhes referente à natureza, extensão da violação e os dados comprometidos, em prazo razoável, de acordo com o disposto nos artigos 48, § 1º, da LGPD.</p>
        <p><strong>8.4. <u>Prazo de Armazenamento</u></strong>. Nós armazenaremos seus dados durante todo o período em que seu cadastro estiver ativo.</p>
        <p><strong>8.5. <u>Encarregado de Proteção de Dados</u></strong>. Fica definido que Thais Aquino será a Encarregada de Proteção de Dados da <strong>StartSe</strong> e poderá ser contatada através do e-mail dpo@startse.com, ou à Rua Capitão Antônio Rosa, 409, Pinheiros, na cidade de São Paulo/SP, CEP 01443-010, nos termos do artigo 41 da Lei Geral de Proteção de Dados (Lei nº 13.709/2018).</p>

        <p id="general" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- DISPOSIÇÕES GERAIS -</strong></p>
        <p><strong>9.1. <u>Responsabilidade do Usuário</u></strong>. <strong>Vocês</strong> serão responsáveis por <strong>Nos</strong> indenizar por todos os custos e danos que este possa incorrer, como resultado da violação desta Política de Privacidade por si provocados.</p>
        <p><strong>9.2. <u>Cooperação com Autoridades</u></strong>. <strong>Nós</strong> cooperamos plenamente com quaisquer autoridades ou tribunais que venham a solicitar a divulgação da identidade ou localização de qualquer pessoa que tenha inserido qualquer material na Plataforma que violem as Cláusulas dispostas nesta Política.</p>
        <p><strong>9.3. <u>Limitação de Responsabilidade</u></strong>. Esta Política de Privacidade aborda apenas o uso e divulgação de informações coletadas pela <strong>Nossa</strong> Plataforma. Se <strong>Vocês</strong> divulgarem suas informações a websites de terceiros, regras diferentes podem ser aplicadas à utilização das suas informações.</p>

        <p id="law" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- LEI APLICÁVEL E JURISDIÇÃO -</strong></p>
        <p><strong>10.1.</strong> Os presentes Termos de Uso são regidos pelas leis brasileiras, tendo como definido o foro da Comarca Central de São Paulo como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por conciliação ou mediação.</p>

        <p id="intellectualProperty" style={{ paddingTop: 64, textAlign: 'center' }}><strong>- PROPRIEDADE INTELECTUAL -</strong></p>
        <p><strong>11.1. <u>Limitação de Uso.</u></strong> </p>
        <p>A aquisição do curso não envolve a obtenção de qualquer direito de Propriedade Intelectual sobre o próprio curso ou a Plataforma, mas, sim, uma licença de uso, concedida de forma limitada, temporária, não exclusiva, intransferível, não customizável e não passível de sublicenciamento.</p>
        <p><strong>11.2. <u>Utilização da Propriedade Intelectual.</u></strong></p>
        <p>O uso comercial da expressão “Inventing th​e next breakthrough ‐ The Israeli Model” como marca, nome empresarial ou nome de domínio pertence à STARTSE. Todos os direitos autorais sobre o curso e os conteúdos disponibilizados na Plataforma, assim como direitos passíveis de proteção por propriedade industrial - marcas, patentes e desenhos industriais, registrados ou não - permanecem sobre titularidade da STARTSE.</p>
        <p><strong>11.3. <u>Proibições.</u></strong></p>
        <p>Não é permitido reproduzir, duplicar, copiar, vender, revender ou de qualquer outra forma explorar, para fins pessoais ou comerciais, sob qualquer meio, o conteúdo e os materiais do curso disponibilizados na Plataforma.</p>
        <p><strong>11.4. <u>Penalidades por Violação.</u></strong></p>
        <p>A violação aos direitos de propriedade intelectual da STARTSE ensejará a imediata exclusão do violador da Plataforma, sem prejuízo das demais responsabilizações legais e contratuais.</p>

        <p style={{ paddingTop: 32 }}>Criada em 12 de setembro de 2020.</p>

        <p style={{ paddingTop: 32 }}>. . .</p>

        <p style={{ paddingTop: 32 }}>Ainda está com alguma dúvida em relação a nossa Política de Privacidade? Contate a nossa Data Protection Officer (DPO): <a href="mailto:dpo@startse.com" style={{ color: '#000', fontWeight: 500, textDecoration: 'initial' }}>dpo@startse.com</a></p>
      </Content>

      <BackToTop />
    </Container>
  )
}
