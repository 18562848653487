import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: #F5F5F5;
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;

    @media screen and (min-width: 1100px) {
      width: 100vw;
      height: 100vh;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    position: relative;
  }

  h1 {
    font-family: 'Barlow', sans-serif;
  }

  .MuiTypography-root.MuiCardHeader-title.MuiTypography-body2.MuiTypography-displayBlock {
    @media screen and (min-width: 1100px) {
      min-height: 38px;
    }
  }
`
