import styled from "styled-components"

export const Container = styled.div`
  with: 100%;
`

export const Content = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 64px 0;

  @media screen and (min-width: 1024px) {
    max-width: 1000px;
    padding-left: 300px;
    padding: 128px 0 64px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 150px;
  }

  @media screen and (min-width: 1900px) {
    max-width: 1000px;
    padding-left: 0;
  }

  & h1 {
    color: #0080ED;
    padding-bottom: 32px;
  }

  & p,
  & li {
    font-size: 18px;
    line-height: 1.6;

    @media screen and (min-width: 1024px) {
      font-size: 22px;
    }
  }

  & p {
    padding: 16px 0 0;
  }

  & li {
    padding: 16px 0px 0px;
  }
  & li:first-child {
    margin-bottom: 0px;
  }

  & ol {
    counter-reset: item;
  }
  & ol li {
    display: block
  }
  & ol li:before {
    content: counters(item, ".") ". "; counter-increment: item
  }

  table {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 16px 0;
  }

  th, td {
    padding: 8px 0;
    line-height: 1.8;

    @media screen and (min-width: 1024px) {
      width: 50%;
    }
  }

  table, th, td {
    border: 1px solid #999;
    border-collapse: collapse;
  }
`

export const NavbarGroup = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    width: 200px;
    min-height: 150px;
    padding: 16px;
    position: fixed;
    border: 1px solid #989898;
    border-radius: 10px;
    margin-top: 140px;
    margin-left: 24px;
    color: #989898;
  }

  & p {
    font-size: 18px;
    padding-bottom: 16px;
    font-weight: 500;
  }

  & ul li {
    list-style: none;
    padding-bottom: 8px;
  }

  & a {
    color: #989898;
    text-decoration: none;
    line-height: 2;
    font-size: 12px;
  }
`
