import React from 'react'
import { HeaderGroup } from './style'

export default function Header() {
  return (
    <HeaderGroup>
      <h1>StartSe Docs</h1>
      <h2>Documentos públicos organizados num só lugar</h2>
    </HeaderGroup>
  )
}