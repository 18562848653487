import React from "react";

import Navbar from "../../../components/Navbar";

import { Container, NavbarGroup, Content } from "../style";

import { useHistory } from "react-router-dom";

export default function Regulation() {
  const history = useHistory();
  function handleRouter(ref) {
    history.push(`/politica-de-privacidade/#${ref}`);
  }
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />
      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li
            onClick={() => {
              handleRouter("rights");
            }}
          >
            <a href="#rights">
              <span>
                <strong>1.</strong> Direitos
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("information");
            }}
          >
            <a href="#information">
              <span>
                <strong>2.</strong> Informações e dados coletados
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("terms");
            }}
          >
            <a href="#terms">
              <span>
                <strong>3.</strong> Termos Gerais
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("data");
            }}
          >
            <a href="#data">
              <span>
                <strong>4.</strong> Dados pessoais coletados
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("sharing");
            }}
          >
            <a href="#sharing">
              <span>
                <strong>5.</strong> Compartilhamento dos dados pessoais
                coletados
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("cookies");
            }}
          >
            <a href="#cookies">
              <span>
                <strong>5.</strong> Cookies
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("access");
            }}
          >
            <a href="#access">
              <span>
                <strong>6.</strong> Acesso e correção dos dados pessoais
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("notification");
            }}
          >
            <a href="#notification">
              <span>
                <strong>7.</strong> Notificação de modificações
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("comunication");
            }}
          >
            <a href="#comunication">
              <span>
                <strong>8.</strong> Comunicação
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("safe");
            }}
          >
            <a href="#safe">
              <span>
                <strong>9.</strong> Segurança de dados pessoais
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("general");
            }}
          >
            <a href="#general">
              <span>
                <strong>10.</strong> Disposições gerais
              </span>
            </a>
          </li>

          <li
            onClick={() => {
              handleRouter("law");
            }}
          >
            <a href="#law">
              <span>
                <strong>11.</strong> Lei aplicável e jurisdição
              </span>
            </a>
          </li>
          <li 
            onClick={() => {
              handleRouter("intellectualProperty");
            }}>
            <a href="#intellectualProperty">
              <span><strong>12.</strong> Propriedade Intelectual</span>
            </a>
          </li>
          <li style={{ backgroundColor: "#e9e9e9" }}>
            <a href="#regulation">
              <span>
                <strong>13.</strong> Regulamento da Promoção
              </span>
            </a>
          </li>

        </ul>
      </NavbarGroup>
      <Content>
        <h1>Regulamento da Promoção "Indique e Ganhe StartSe"</h1>
        <p style={{ marginBottom: "20px" }}>
          Abaixo você encontra o regulamento completo da promoção.
        </p>
        <a
          href="https://startse-landings.s3.us-east-2.amazonaws.com/download/docs/regulamento-indique-e-ganhe-startse-2021.pdf"
          style={{ color: "#000", fontWeight: 500, textDecoration: "initial" }}
        >
          Clique aqui para baixar o regulamento
        </a>
      </Content>
    </Container>
  );
}
