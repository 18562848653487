import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function Prime() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Assinatura</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Planos</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Pagamento</span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span><strong>4.</strong> Cancelamento</span>
            </a>
          </li>

          <li>
            <a href="#5">
              <span><strong>5.</strong> Disposições gerais</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Políticas Gerais de Cancelamento - StartSe PRIME</h1>
        <p>A presente política tem por objetivo informar sobre as regras estabelecidas para o acesso e cancelamento do StartSe PRIME, cuja assinatura se dá exclusivamente no site da <strong>STARTSE</strong> (www.startse.com/prime), com amparo no artigo 49 da Lei nº 8.078/1990 (“Política”).</p>
        <p>O Participante que assinar o StartSe PRIME, atesta que leu integralmente e está de acordo com a presente Política, com os termos de uso e política de privacidade e cancelamento referentes à mesma</p>

        <p id="1"><strong>1. ASSINATURA:</strong></p>
        <p>1.1. <u>Da assinatura:</u> A assinatura e o acesso ao conteúdo e benefícios da plataforma somente serão liberados após a devida comprovação de pagamento pela instituição financeira competente.</p>
        <p>1.2. <u>Do acesso:</u> O acesso é unitário, sendo realizado via login e senha única, não podendo ser compartilhado com outras pessoas. A jornada de aprendizado é personalizada e cada assinante montará a trilha que desejar, sendo sempre tratado na sua individualidade.</p>
        
        <p id="2"><strong>2. PLANO:</strong></p>
        <p>2.1. <u>Do plano:</u> O StartSe PRIME conta com 1 modelo de plano anual e este conta com recorrência automática ativa.</p>
        <p>2.1.1. <u>Do faturamento:</u> O valor de R$ 178,80 (equivalente a R$14,90 por mês) é faturado anualmente (em 1x, sem possibilidade de parcelamento) sendo renovado automaticamente após 1(um) ano e permite acesso de 1 (um) ano à plataforma para 1 (um) usuário.</p>
        <p>2.2 <u>Do período de trial:</u> Estamos oferecendo um período de teste especial de 60 (sessenta) dias, ou seja a primeira cobrança só será feita depois de 30 dias de teste grátis, período em que a pessoa pode cancelar sem ser cobrada. Solicitamos os dados do cartão de crédito no momento da inscrição, mas a primeira cobrança será efetivada apenas após 30 dias de teste grátis.</p>

        <p id="3"><strong>3. PAGAMENTO:</strong></p>
        <p>3.1 <u>Da forma de pagamento:</u> Será aceito apenas pagamento via cartão de crédito. Não trabalhamos com boletos e nem parcelamentos nos planos.</p>

        <p id="4"><strong>4. CANCELAMENTO:</strong></p>
        <p>4.1: Se o assinante decidir cancelar sua assinatura poderá o fazer automaticamente, acessando na sua área logada do PRIME, no menu “Editar Perfil > Minha conta > Prime > Cancelar assinatura, de forma autônoma e rápida.</p>
        <p>4.1.1: Se o cancelamento for solicitado antes de fechar o sétimo dia da assinatura, não será cobrado nenhuma fatura no cartão de crédito e o acesso à plataforma é imediatamente impedido.</p>
        <p>4.1.2: Se o cancelamento for solicitado após o sétimo dia da assinatura, a fatura será cobrada, encerrando a próxima e o acesso à plataforma será concedido até a data final contratada.</p>

        <p id="5"><strong>5. DISPOSIÇÕES GERAIS:</strong></p>
        <p>5.1. <u>Dos Pagamentos Duplicados:</u> Caso o Assinante tenha pago duas vezes a assinatura, a <strong>STARTSE</strong> irá realizar o reembolso do valor duplicado após a confirmação no meio de pagamento utilizado.</p>
        <p>5.2. O Assinante declara ter recebido a presente Política pela internet, lido e compreendido seu inteiro teor, redigido em linguagem clara e consciente dos direitos e condicionantes que limitam o cumprimento do seu objeto, anuindo com todos os seus termos.</p>

        <p style={{ marginTop: 32 }}>São Paulo, 15 de Outubro de 2020.</p>
        <p><strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong></p>
      </Content>

      <BackToTop />
    </Container>
  )
}
