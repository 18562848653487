import React from 'react'
import useClipboard from 'react-use-clipboard'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ButtonCopy from './components/ButtonCopy'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { CardGroup } from './style'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      borderRadius: 10,
    },
    paper: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      position: 'sticky',
      top: 0,
      boxShadow: 'none',
      marginBottom: 32,
      minHeight: 40,
    },
    tabs: {
      backgroundColor: '#F1F1F1',
      color: '#0080ED',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      minHeight: 40,
    },
    linkTab: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      fontSize: 15,
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    avatar: {
      backgroundColor: '#0080ED',
    },
  }),
)

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

export default function Cards() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [isCookiesCopied, setCookiesCopied] = useClipboard(`https://docs.startse.com/politica-de-cookies`)
  const [isGeneralCopied, setGeneralCopied] = useClipboard(`https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao`)
  const [isGeneralv2Copied, setGeneralv2Copied] = useClipboard(`https://docs.startse.com/politicas-gerais-de-cancelamento-e-remarcacao-v2`)
  const [isImageUseCopied, setImageUseCopied] = useClipboard(`https://docs.startse.com/termo-de-autorizacao-de-uso-de-imagem-e-voz`)
  const [isInternationalCopied, setInternationalCopied] = useClipboard(`https://docs.startse.com/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais`)
  const [isPrimeCopied, setPrimeCopied] = useClipboard(`https://docs.startse.com/politicas-gerais-de-cancelamento-startse-prime`)
  const [isPrivacyCopied, setPrivacyCopied] = useClipboard(`https://docs.startse.com/politica-de-privacidade`)
  const [isPaymentCopied, setPaymentCopied] = useClipboard(`https://docs.startse.com/termo-de-cobranca-de-encargos-por-atraso`)

  return (
    <>
      <div className={classes.paper}>
        <AppBar className={classes.appBar}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Navbar com categorias dos cards"
            indicatorColor="primary"
            className={classes.tabs}
          >
            <LinkTab label="Todos" href="/todos" className={classes.linkTab} {...a11yProps(0)} />
            <LinkTab label="Nacionais" href="/nacionais" className={classes.linkTab} {...a11yProps(1)} />
            <LinkTab label="Internacionais" href="/internacionais" className={classes.linkTab} {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <CardGroup>
            <Card className={classes.root}>
              <Link
                  to='/termo-de-cobranca-de-encargos-por-atraso'
                  style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        S
                      </Avatar>
                    }
                    title="Termo de Cobrança de Encargos por Atraso"
                    subheader="15 de Junho de 2023"
                />
              </Link>

              <Link
                  to='/termo-de-cobranca-de-encargos-por-atraso'
                  style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                    className={classes.media}
                    image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/prime.jpg"
                    title="Códigos de programação em tela preta"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Informa sobre as regras estabelecidas referente ao cumprimento dos pagamento
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setPaymentCopied}>
                  <ButtonCopy />
                </div>

                {isPaymentCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politica-de-privacidade'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Privacidade"
                  subheader="12 de Setembro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-privacidade'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/privacy.png"
                  title="Códigos de programação em tela preta"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Vocês são os titulares (pessoas naturais) de dados pessoais a quem se referem os dados pessoais que serão coletados por Nós. A legislação brasileira concede certos direitos...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setPrivacyCopied}>
                  <ButtonCopy />
                </div>

                {isPrivacyCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politica-de-cookies'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Cookies"
                  subheader="04 de Setembro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-cookies'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/cookies.jpg"
                  title="Códigos de programação em tela preta"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A StartSe, com o objetivo de oferecer uma melhor experiência a você, utiliza cookies para permitir que o funcionamento da plataforma seja personalizado para cada usuário....
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setCookiesCopied}>
                  <ButtonCopy />
                </div>

                {isCookiesCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                  to='/politicas-gerais-de-cancelamento-e-remarcacao-v2'
                  style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        S
                      </Avatar>
                    }
                    title="Políticas Gerais de Cancelamento e Remarcação V2 – Compras a partir 15/05/2023"
                    subheader="12 de Abril de 2023"
                />
              </Link>

              <Link
                  to='/politicas-gerais-de-cancelamento-e-remarcacao-v2'
                  style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                    className={classes.media}
                    image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/offline-event.jpg"
                    title="Pessoas sentadas, assistindo uma palestra; ambiente mais escuro"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política tem por objetivo informar sobre as regras estabelecidas para as matrículas, reagendamentos, cancelamentos e reembolsos relacionados à todos...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setGeneralv2Copied}>
                  <ButtonCopy />
                </div>

                {isGeneralv2Copied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politicas-gerais-de-cancelamento-e-remarcacao'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Políticas Gerais de Cancelamento e Remarcação – Compras efetuadas até 14/05/2023"
                  subheader="02 de Dezembro de 2020"
                />
              </Link>

              <Link
                to='/politicas-gerais-de-cancelamento-e-remarcacao'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/offline-event.jpg"
                  title="Pessoas sentadas, assistindo uma palestra; ambiente mais escuro"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política tem por objetivo informar sobre as regras estabelecidas para as matrículas, reagendamentos, cancelamentos e reembolsos relacionados à todos...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setGeneralCopied}>
                  <ButtonCopy />
                </div>

                {isGeneralCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politicas-gerais-de-cancelamento-startse-prime'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Políticas Gerais de Cancelamento - StartSe PRIME"
                  subheader="15 de Outubro de 2020"
                />
              </Link>

              <Link
                to='/politicas-gerais-de-cancelamento-startse-prime'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/prime.jpg"
                  title="Foto com foco em rosto de homem olhando para tela de notebook; notebook desfocado ao fundo"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política tem por objetivo informar sobre as regras estabelecidas para o acesso e cancelamento do StartSe PRIME, cuja assinatura se dá exclusivamente no site da <strong>STARTSE</strong>...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setPrimeCopied}>
                  <ButtonCopy />
                </div>

                {isPrimeCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Matrícula, Cancelamento e Reembolso - Imersões Internacionais"
                  subheader="14 de Janeiro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/golden-gate-bridge.jpg"
                  title="Golden Gate Bridge"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política de matrícula, cancelamento e reembolso tem por objetivo informar sobre as regras estabelecidas para as matrículas, eventual cancelamento e reembolso das Imersões...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setInternationalCopied}>
                  <ButtonCopy />
                </div>

                {isInternationalCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/termo-de-autorizacao-de-uso-de-imagem-e-voz'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Termo de Autorização de Uso de Imagem e Voz"
                  subheader="11 de Maio de 2020"
                />
              </Link>

              <Link
                to='/termo-de-autorizacao-de-uso-de-imagem-e-voz'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/image-use.jpg"
                  title="Mulheres com iPhones nas mãos; sentadas; tirando fotos do palco; evento Varejotech Conference 2019."
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Eu <strong>​AUTORIZO</strong> a captação, fixação, edição e utilização de minhas imagens e voz para serem inseridas e utilizados nos meios de comunicação da ​<strong>STARTSE INFORMAÇÕES...</strong>
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setImageUseCopied}>
                  <ButtonCopy />
                </div>

                {isImageUseCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>
          </CardGroup>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CardGroup>
            <Card className={classes.root}>
              <Link
                to='/politica-de-privacidade'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Privacidade"
                  subheader="12 de Setembro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-privacidade'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/privacy.png"
                  title="Códigos de programação em tela preta"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Vocês são os titulares (pessoas naturais) de dados pessoais a quem se referem os dados pessoais que serão coletados por Nós. A legislação brasileira concede certos direitos...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setPrivacyCopied}>
                  <ButtonCopy />
                </div>

                {isPrivacyCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politica-de-cookies'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Cookies"
                  subheader="04 de Setembro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-cookies'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/cookies.jpg"
                  title="Códigos de programação em tela preta"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A StartSe, com o objetivo de oferecer uma melhor experiência a você, utiliza cookies para permitir que o funcionamento da plataforma seja personalizado para cada usuário....
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setCookiesCopied}>
                  <ButtonCopy />
                </div>

                {isCookiesCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politicas-gerais-de-cancelamento-e-remarcacao'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Políticas Gerais de Cancelamento e Remarcação – Compras efetuadas até 14/05/2023"
                  subheader="02 de Dezembro de 2020"
                />
              </Link>

              <Link
                to='/politicas-gerais-de-cancelamento-e-remarcacao'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/offline-event.jpg"
                  title="Pessoas sentadas, assistindo uma palestra; ambiente mais escuro"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política tem por objetivo informar sobre as regras estabelecidas para as matrículas, reagendamentos, cancelamentos e reembolsos relacionados à todos...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setGeneralCopied}>
                  <ButtonCopy />
                </div>

                {isGeneralCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/politicas-gerais-de-cancelamento-startse-prime'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Políticas Gerais de Cancelamento - StartSe PRIME"
                  subheader="15 de Outubro de 2020 "
                />
              </Link>

              <Link
                to='/politicas-gerais-de-cancelamento-startse-prime'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/prime.jpg"
                  title="Foto com foco em rosto de homem olhando para tela de notebook; notebook desfocado ao fundo"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política tem por objetivo informar sobre as regras estabelecidas para o acesso e cancelamento do StartSe PRIME, cuja assinatura se dá exclusivamente no site da <strong>STARTSE</strong>...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setPrimeCopied}>
                  <ButtonCopy />
                </div>

                {isPrimeCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>

            <Card className={classes.root}>
              <Link
                to='/termo-de-autorizacao-de-uso-de-imagem-e-voz'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Termo de Autorização de Uso de Imagem e Voz"
                  subheader="11 de Maio de 2020"
                />
              </Link>

              <Link
                to='/termo-de-autorizacao-de-uso-de-imagem-e-voz'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/image-use.jpg"
                  title="Mulheres com iPhones nas mãos; sentadas; tirando fotos do palco; evento Varejotech Conference 2019."
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Eu <strong>​AUTORIZO</strong> a captação, fixação, edição e utilização de minhas imagens e voz para serem inseridas e utilizados nos meios de comunicação da ​<strong>STARTSE INFORMAÇÕES...</strong>
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setImageUseCopied}>
                  <ButtonCopy />
                </div>

                {isImageUseCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>
          </CardGroup>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <CardGroup>
            <Card className={classes.root}>
              <Link
                to='/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      S
                    </Avatar>
                  }
                  title="Política de Matrícula, Cancelamento e Reembolso - Imersões Internacionais"
                  subheader="14 de Janeiro de 2020"
                />
              </Link>

              <Link
                to='/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais'
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87' }}
              >
                <CardMedia
                  className={classes.media}
                  image="https://startse-landings.s3.us-east-2.amazonaws.com/docs/img/cards/golden-gate-bridge.jpg"
                  title="Golden Gate Bridge"
                />
              </Link>

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  A presente política de matrícula, cancelamento e reembolso tem por objetivo informar sobre as regras estabelecidas para as matrículas, eventual cancelamento e reembolso das Imersões...
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <div onClick={setInternationalCopied}>
                  <ButtonCopy />
                </div>

                {isInternationalCopied && "Link copiado! 👍"}
              </CardActions>
            </Card>
          </CardGroup>
        </TabPanel>
      </div>
    </>
  )
}
