import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: 50,
  },
}))

export default function TextButtons() {
  const classes = useStyles()

  return (
    <div>
      <label htmlFor="text-button-file">
        <Button component="span" className={classes.button}>
          Copiar link
        </Button>
      </label>
    </div>
  )
}
