import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function Cookies() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Sobre</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Uso</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Recusa</span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span><strong>4.</strong> Modificações</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Política de Cookies</h1>
        <p>A <strong>StartSe</strong>, com o objetivo de oferecer uma melhor experiência a <strong>você</strong>, utiliza cookies para permitir que o funcionamento da plataforma seja personalizado para cada usuário.</p>

        <p id="1"><strong><u>Mas, afinal, o que é um cookie?</u></strong></p>
        <p>Cookie é um pequeno arquivo de texto enviado para o disco rígido do seu computador, através de seu navegador, para que você seja lembrado pelo nosso site na próxima vez que acessá-lo. Além disso, esses cookies permitem que entendamos algumas métricas e possamos, cada vez mais, melhorar nosso serviço.</p>
        <p>Esses cookies geralmente são formados pelo nome do seu domínio de origem, seu "tempo de vida" (ou seja, por quanto tempo permanecerá em seu dispositivo) e um valor, normalmente um número único gerado aleatoriamente.</p>

        <p id="2"><strong><u>Que uso damos aos cookies?</u></strong></p>
        <p>Os "cookies" podem ser usados de diferentes formas, como descritas abaixo:</p>
        <p><u>i. Categoria 1: Cookies Funcionais</u> <br />Estes cookies são usados pelo nosso website por algumas de suas funcionalidades, como por exemplo, para sabermos o número de usuários ativos e sua retenção. Com isso, podemos fazer adaptações de forma a melhorar a experiencia do usuário.</p>
        <p><u>ii. Categoria 2: Cookies de desempenho</u> <br />Este tipo de cookie coleta informações de texto sobre a forma como os nossos usuários utilizam o site, para podermos otimizá-lo. Por exemplo, cookie que permite saber se você escolheu a opção “Mantenha-me conectado” para que o site entre automaticamente na conta da próxima vez que voltar à página.</p>
        <p><u>iii. Categoria 3: Cookies publicitários</u> <br />Estes cookies coletam informações sobre os seus hábitos de navegação, buscando tornar a publicidade mais relevante para você e para os seus interesses. Eles memorizam os websites que você visitou e compartilham essa informação com outros anunciantes. Em nosso caso, usamos terceiros, como a Google, para lhe oferecer anúncios mais relevantes enquanto você visita outros sites na internet.</p>

        <p id="3"><strong><u>Posso me recusar a utilizar cookies?</u></strong></p>
        <p>Claro! É possível modificar as configurações do seu navegador para impedir o armazenamento de cookies no seu computador ou dispositivo móvel sem o seu consentimento prévio. A seção de ajuda do navegador deverá conter mais informações sobre como modificar as definições de armazenamento de cookies. Por favor, note que isso não impedirá que lhe sejam exibidos anúncios em outros sites, apenas tornará esses anúncios menos relevantes para você. Além disso, desabilitar os cookies que utilizamos, pode impactar sua experiência em nossa plataforma.</p>
        <p>Claro! É possível modificar as configurações do seu navegador para impedir o armazenamento de cookies no seu computador ou dispositivo móvel sem o seu consentimento prévio. A seção de ajuda do navegador deverá conter mais informações sobre como modificar as definições de armazenamento de cookies. Por favor, note que isso não impedirá que lhe sejam exibidos anúncios em outros sites, apenas tornará esses anúncios menos relevantes para você. Além disso, desabilitar os cookies que utilizamos, pode impactar sua experiência em nossa plataforma.</p>

        <p id="4"><strong><u>Modificações à política de Cookies</u></strong></p>
        <p>Podemos modificar essa política de cookies a qualquer momento dentro dos respectivos limites legais. Se fizermos alterações substanciais, iremos lhe notificar assim que retornar ao nosso site.</p>
        <p>É importante que você confira qualquer nova política de cookies implementada pela <strong>StartSe</strong>. Caso não a aceite, estaremos impedidos de prestar-lhe nossos serviços</p>

        <p style={{ marginTop: 32 }}>Criada em 02 de Setembro de 2020.</p>
      </Content>

      <BackToTop />
    </Container>
  )
}
