import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import Cookies from './pages/Documents/Cookies'
import General from './pages/Documents/General'
import General_v2 from './pages/Documents/General_v2'
import ImageUse from './pages/Documents/ImageUse'
import International from './pages/Documents/International'
import Payment from "./pages/Documents/Payment";
import Prime from './pages/Documents/Prime'
import Privacy from './pages/Documents/Privacy'
import Regulation from './pages/Documents/Regulation'
import { GlobalStyle } from './components/Styled'

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0080ED",
      },
      secondary: {
        main: "#EF6E67",
      },
      success: {
        main: "#2DB5B2",
        dark: "#1E706E",
        light: "#E3FCFC",
      },
    },
    typography: {
      fontFamily: 'IBM Plex Sans, Barlow'
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename="/docs/">
        <GlobalStyle />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/politica-de-cookies" component={Cookies} />
          <Route path="/termo-de-cobranca-de-encargos-por-atraso" component={Payment} />
          <Route path="/politicas-gerais-de-cancelamento-e-remarcacao" component={General} />
          <Route path="/politicas-gerais-de-cancelamento-e-remarcacao-v2" component={General_v2} />
          <Route path="/termo-de-autorizacao-de-uso-de-imagem-e-voz" component={ImageUse} />
          <Route path="/politica-de-matricula-cancelamento-e-reembolso-imersoes-internacionais" component={International} />
          <Route path="/politicas-gerais-de-cancelamento-startse-prime" component={Prime} />
          <Route path="/politica-do-regulamento" component={Regulation} />
          <Route path="/politica-de-privacidade" component={Privacy} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
