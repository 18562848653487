import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function Payment() {
    return (
        <Container>
            <Container id="back-to-top-anchor" />

            <Navbar />

            <NavbarGroup aria-label="Conteúdo da página">
                <p>Índice</p>

                <ul>
                    <li>
                        <a href="#1">
                            <span><strong>1.</strong> Termo de cobrança de encargos por atraso</span>
                        </a>
                    </li>
                </ul>
            </NavbarGroup>

            <Content>
                <h1>Termo de cobrança de encargos por atraso</h1>
                <p id="1">Este documento tem como objetivo informar sobre as regras estabelecidas referente ao cumprimento do pagamento na data de vencimento acordada, sendo que, o atraso no pagamento de quaisquer valores devidos à STARTSE, quando causado pela Contratante, implicará em juros de mora de 1% a.m. (um por cento ao mês), calculado de forma pro rata die (proporcional aos dias), e a multa não compensatória irredutível de 2% (dois por cento), calculados sobre o valor devido, tudo incidente sobre o valor vencido e não pago, corrigido monetariamente pela variação percentual acumulada do índice IGP-M/FGV, ou em caso de sua extinção, por outro índice oficial que vier a substituí-lo. </p>

                <p style={{ marginTop: 32 }}>São Paulo, 15 de Junho de 2023.</p>
                <p><strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong></p>
            </Content>

            <BackToTop />
        </Container>
    )
}
