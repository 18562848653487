import React from 'react'
import Navbar from '../../components/Navbar'
import Header from '../../components/Header'
import Cards from '../../components/Cards'
import BackToTop from '../../components/BackToTop'
import { Container } from './style'

export default function Home() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <Header />

      <Cards />

      <BackToTop />
    </Container>
  );
}
